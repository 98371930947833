<template>
  <!-- 店铺信息 -->
  <div class="shopList">
    <div class="shopList__left box-shadow scrollbar flex directionColumn">
      <el-tabs v-model="activeName" class="wHP" style="margin-left: 3%" @tab-click="activeClick">
        <el-tab-pane label="渠道" name="first"></el-tab-pane>
        <el-tab-pane label="区域" name="third"></el-tab-pane>
        <el-tab-pane label="权属" name="fourth"></el-tab-pane>
      </el-tabs>
      <div v-if="activeName === 'third' || activeName === 'fourth'" class="padding10">
        <el-input v-model="filterText" placeholder="分类名称" class="wHP" clearable />
      </div>
      <div>
        <el-tabs v-if="activeName === 'first'" v-model="zType" tab-position="left" @tab-click="handleClick">
          <el-tab-pane label="美团闪购" name="0"></el-tab-pane>
          <el-tab-pane label="饿了么零售" name="1"></el-tab-pane>
          <el-tab-pane label="京东到家" name="2"></el-tab-pane>
          <el-tab-pane label="美团外卖" name="3"></el-tab-pane>
        </el-tabs>
        <el-tree v-show="activeName === 'third'" ref="thirdTree" show-checkbox class="filter-tree" :data="data" :default-expand-all="false" :filter-node-method="filterNode" @check-change="handleCheckChange" />
        <el-tree v-show="activeName === 'fourth'" ref="merchantTree" show-checkbox class="filter-tree" :props="merchantProps" :data="merchantList" :default-expand-all="true" :filter-node-method="merchantFilter" @check-change="handleCheckChange" />
      </div>
    </div>

    <div class="shopList__right box-shadow">
      <div class="fs16 fWB borderBottom paddingBottom10">店铺列表</div>
      <dc-search-panel :params="queryPara" @search="doSearch" @reset="resetQueryPara">
        <el-select v-model="queryPara.bStatus" placeholder="全部状态">
          <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-input v-model="queryPara.cName" clearable placeholder="输入店铺名称" class="marginLeft10 w250 input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="doSearch"></el-button>
        </el-input>
        <div style="margin-top: 10px">
          <el-select v-model="syncShopType" placeholder="请选择同步平台">
            <el-option v-for="item in shopType" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-button size="small" type="primary" style="margin-left: 5px" :loading="syncLoading" @click="syncShop">同步</el-button>
        </div>
      </dc-search-panel>
      <dc-datatable ref="list" :url="urlApi" :params="queryPara">
        <el-table-column label="序号" align="center" type="index" width="60" />
        <el-table-column align="center" label="渠道" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.ztype === 0">美团闪购</span>
            <span v-else-if="scope.row.ztype === 1">饿了么零售</span>
            <span v-else-if="scope.row.ztype === 2">京东到家</span>
          </template>
        </el-table-column>
        <el-table-column prop="cname" align="center" label="店铺名称" width="470" />
        <el-table-column prop="rplatShop" align="center" label="平台店铺ID" />
        <el-table-column align="center" label="状态" width="70">
          <template slot-scope="scope">
            <span v-if="scope.row.bstatus === true" slot="reference" class="father1" style="color: rgb(20, 196, 236)">营业中</span>
            <span v-else slot="reference" class="father1" style="color: rgb(164, 181, 184)">未营业</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="editRow(scope.row.sid)">编辑</el-button>
            <el-button type="text" size="mini" @click="deleteRow(scope.row)">
              <span class="red">删除</span>
            </el-button>
          </template>
        </el-table-column>
      </dc-datatable>
    </div>

    <el-dialog :title="title" :visible.sync="open" width="22%" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="90px">
        <el-form-item label="店铺名称" prop="cname">
          <el-input v-model="form.cname" placeholder="请输入店铺名称" />
        </el-form-item>
        <el-form-item label="绑定权属" prop="rmerchant">
          <el-select v-model="form.rmerchant" filterable placeholder="请选择">
            <el-option v-for="item in merchantList" :key="item.sid" :label="item.c_name" :value="item.sid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定门店" prop="storeId">
          <el-select v-model="form.storeId" filterable placeholder="请选择">
            <el-option v-for="item in storeList" :key="item.sid" :label="item.cname" :value="item.sid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在地区">
          <el-cascader v-model="form.region" :options="options" :props="defaultProps" @change="onChangeRegion" />
        </el-form-item>
        <el-form-item label="营业状态" prop="status">
          <el-switch v-model="form.bstatus"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="submitForm">保存</el-button>
        <el-button size="small" type="danger" @click="cancel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deleteshopById, getShopById, queryAreaList, queryList, queryMerchantList, updateShopById } from "../../api/shop/shop";
import baseUrls from "../../api/baseUrls";
import { addSorts, updateSorts } from "@/api/goods/goodsSort";

export default {
  name: "ShopList",
  props: {},
  data() {
    return {
      activeName: "third",
      zType: null,
      tableData: [],
      open: false,
      title: "店铺修改",
      cname: "",
      data: [],
      form: {},
      merchantList: [],
      merchantProps: {
        children: "children",
        label: "c_name",
      },
      storeList: [],
      value: "",
      rules: {},
      merchantData: [],
      filterText: "",
      merchantText: "",
      queryPara: {
        bStatus: null,
        cName: null,
      },
      syncShopType: 0,
      syncLoading: false,
      options: [],
      defaultProps: {
        children: "children",
        label: "NODE_NAME",
        value: "NODE_CODE",
      },
      stateOptions: [
        { value: null, label: "全部状态" },
        { value: false, label: "未营业" },
        { value: true, label: "营业中" },
      ],
      shopType: [
        { value: 0, label: "全部" },
        { value: 1, label: "饿了么" },
        { value: 2, label: "美团" },
        { value: 3, label: "京东到家" },
      ],
      bStatus: null,
      urlApi: `${this.baseUrls.shop}/queryPageList`,
      url: "https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg",
      tabs: "1",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.thirdTree.filter(val);
      this.$refs.merchantTree.filter(val);
    },
  },
  created() {
    queryAreaList().then((response) => {
      this.data = response.data;
    });
    queryMerchantList().then((response) => {
      this.merchantData = response.data;
    });
    this.selectMerchant();
    this.selectStore();
  },
  mounted() {},
  methods: {
    onChangeRegion(val) {
      this.form.cprovincial = val[0];
      this.form.ccity = val[1];
      this.form.carea = val[2];
    },
    /**
     * 获取省市县数据
     */
    queryBaseAreaList() {
      let url = "/api/system/baseArea/queryList";
      this.topsAjax.get(url).then((res) => {
        res.forEach((item) => {
          if (item.children) {
            item.children.forEach((subItem) => {
              if (subItem.children) {
                subItem.children.forEach((thirdItem) => {
                  if (thirdItem.children) {
                    delete thirdItem.children; // 删除第四层 children
                  }
                });
              }
            });
          }
        });
        this.options = res;
      });
    },
    resetQueryPara() {
      this.bStatus = null;
      this.zType = "-1";
      this.doSearch();
    },
    handleClick(tab, event) {
      this.doSearch();
    },
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          updateShopById(this.form).then((response) => {
            this.$message.success("修改成功");
            this.open = false;
            this.$refs.list.load();
          });
        }
      });
    },
    cancel() {
      this.open = false;
    },
    selectMerchant() {
      let url = `${this.baseUrls.merchant}/selectMerchantTree`;
      this.topsAjax.get(url).then((res) => {
        this.merchantList = res;
      });
    },
    selectStore() {
      let url = `${this.baseUrls.store}/queryList`;
      this.topsAjax.get(url).then((response) => {
        this.storeList = response;
      });
    },
    activeClick(tab, event) {
      this.queryPara.zType = null;
      this.queryPara.areaIds = null;
      this.queryPara.merchantIds = null;
      this.queryPara.bStatus = this.bStatus;
      this.queryPara.cName = this.cname;
      this.$refs.list.load();
    },
    deleteRow(row) {
      this.$confirm('是否确认删除店铺:"' + row.cname + '"？')
        .then(function () {
          return deleteshopById(row.sid);
        })
        .then(() => {
          this.doSearch();
          this.$message("删除成功");
        })
        .catch(() => {});
    },
    editRow(sid) {
      this.open = true;
      getShopById(sid).then((response) => {
        this.form = response.data;
        let region = [];
        region.push(this.form.cprovincial);
        region.push(this.form.ccity);
        region.push(this.form.carea);
        this.form.region = region;
      });
      this.queryBaseAreaList();
    },
    handleCheckChange(data, checked) {
      if (checked) {
        let ids;
        if (data.children) {
          ids = data.children.map((node) => node.sid);
        } else {
          ids = data.sid;
        }
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    merchantFilter(value, data) {
      if (!value) return true;
      return data.c_name.indexOf(value) !== -1;
    },
    syncShop() {
      if (this.syncShopType === 2) {
        this.$message.info("渠道还在建设中...");
        return;
      }
      this.syncLoading = true;
      let url = `${this.baseUrls.shop}/syncShops/` + this.syncShopType;
      this.topsAjax.get(url).then((res) => {
        this.syncLoading = false;
        if (res === "success") {
          this.$message.success("同步完成");
        } else {
          this.$message.error("同步失败");
        }
        this.doSearch();
      });
    },
    doSearch() {
      if (this.activeName === "third") {
        const checkedNodes = this.$refs.thirdTree.getCheckedNodes();
        this.queryPara.areaIds = checkedNodes.map((node) => node.id) + "";
        this.queryPara.zType = null;
        this.queryPara.merchantIds = null;
      } else if (this.activeName === "first") {
        this.queryPara.zType = this.zType;
        this.queryPara.areaIds = null;
        this.queryPara.merchantIds = null;
      } else if (this.activeName === "fourth") {
        this.queryPara.zType = null;
        this.queryPara.areaIds = null;
        const checkedNodes = this.$refs.merchantTree.getCheckedNodes();
        this.queryPara.merchantIds = checkedNodes.map((node) => node.sid) + "";
      } else {
        this.queryPara.bStatus = this.bStatus;
        this.queryPara.cName = this.cname;
      }
      this.$refs.list.load();
    },
  },
};
</script>

<style lang="scss" scoped>
.shopList {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
  overflow: hidden;

  &__left {
    width: 15%;
    background-color: white;
    margin: 3px;
    margin-right: 15px;
  }

  &__right {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 3px;
    background-color: white;
  }
}
</style>

<style lang="scss">
.el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}

.el-tabs--left .el-tabs__header.is-left {
  width: 100%;
}

.father1 {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
</style>
